import axios from 'axios';
import BitesApi, { API_BASE_URL } from '..';

import { IPBASE_TOKEN } from '../../../constants/app';
import { ISubtitles, StorageS3 } from '../../../types/bite';
import { isProd } from '../../../utils/env';

// const API_BASE_URL = 'http://localhost:3002';

export const BITES_UI_BUCKET = isProd()
  ? 'https://static.mybites.io/staticfiles/bites-ui'
  : 'https://herolo-bites2-dev.s3.eu-central-1.amazonaws.com/staticfiles/bites-ui';
export const BITES_WEB_BUCKET = isProd()
  ? 'https://static.mybites.io/staticfiles/bites-web'
  : 'https://herolo-bites2-dev.s3.eu-central-1.amazonaws.com/staticfiles/bites-web';

export const getConfig = () => {
  return axios.get(`${BITES_UI_BUCKET}/config.json`);
};

export const loadTranslations = () => {
  return axios.get(`${BITES_WEB_BUCKET}/translations/${isProd() ? 'prod' : 'staging'}.json`, {});
};

let getGeolocationPromise = null;
export const getGeolocation = () => {
  getGeolocationPromise = getGeolocationPromise || axios.get(`https://api.ipbase.com/v2/info?apikey=${IPBASE_TOKEN}`);
  return getGeolocationPromise;
};

export const getContentLocales = () => {
  return axios.get(`${BITES_UI_BUCKET}/configs/content-locales.json`);
};

export const translateTexts = (params: { orgId: number; texts: string[]; locale: string }) => {
  return axios.post(`${API_BASE_URL}/api/common_services/content/translate`, params);
};

export const translateSubtitles = (params: { taskId: string; orgId: number; locale: string }) => {
  return axios.post<ISubtitles>(`${API_BASE_URL}/api/common_services/content/subtitles/translate`, params);
};

export const translateVoiceovers = (params: {
  taskId: string;
  locale: string;
  enhanceType: 'original' | 'speech_enhancement';
  videoStorage: StorageS3;
  withPresignedUrls?: boolean;
}) => {
  return BitesApi.post<ISubtitles>(`${API_BASE_URL}/api/common_services/content/voiceovers/translate`, params);
};
