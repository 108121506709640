import { useCallback, useEffect, useRef, useState } from 'react';
import { log, logError } from '../../../store/tracking/tracking.slice';
import withRetry from '../../../utils/withRetry';
import { translateTexts } from '../../../store/api/calls/common.calls';
import { useDispatch, useSelector } from 'react-redux';
import { useIsMounted } from '../../../hooks/useIsMounted';
import { orgSelector } from '../../../store/organization/organization.selectors';

interface IProps {
  activeQuestion: {
    id: number;
    text: string;
    type: string;
  };
  sortedChoices: any[];
}

const useQuizTranslation = ({ activeQuestion, sortedChoices }: IProps) => {
  const dispatch = useDispatch();
  const isMountedRef = useIsMounted();

  const org = useSelector(orgSelector);

  const translatedLocaleRef = useRef(null);
  const questionIdRef = useRef(null);
  const requestCounterRef = useRef(0);

  const [isLoadingTranslations, setIsLoadingTranslations] = useState(false);
  const [questionTranslation, setQuestionTranslation] = useState(null);
  const [choicesTranslations, setChoicesTranslations] = useState([]);
  const [locale, setLocale] = useState(null);

  const fetchLocale = useCallback(async () => {
    const startTs = Date.now();
    const requestCounter = ++requestCounterRef.current;

    questionIdRef.current = activeQuestion.id;
    translatedLocaleRef.current = locale;
    setQuestionTranslation(null);
    setChoicesTranslations([]);

    dispatch(
      log({
        event: 'useQuizTranslation.fetchLocale: start',
        data: {
          locale,
        },
      }),
    );

    if (!locale) {
      return;
    }

    setIsLoadingTranslations(true);

    try {
      const optionTexts = activeQuestion.type !== 'open ended' ? sortedChoices.map(({ text }) => text) : [];

      const {
        data: { texts },
      } = await withRetry(
        () =>
          translateTexts({
            orgId: org.id,
            texts: [activeQuestion.text, ...optionTexts],
            locale,
          }),
        {
          errorContext: {
            action: 'useQuizTranslation.fetchLocale',
          },
        },
      );

      if (!isMountedRef.current || requestCounter !== requestCounterRef.current) {
        setIsLoadingTranslations(false);
        return;
      }

      const newQuestionTranslation = texts.shift();
      const newChoicesTranslations = texts;

      setQuestionTranslation(newQuestionTranslation);
      setChoicesTranslations(newChoicesTranslations);
    } catch (error) {
      dispatch(
        logError({
          event: 'useQuizTranslation.fetchLocale: error',
          data: {
            error,
            locale,
          },
        }),
      );
    }

    dispatch(
      log({
        event: 'useQuizTranslation.fetchLocale: done',
        data: {
          locale,
          duration: Date.now() - startTs,
        },
      }),
    );

    setIsLoadingTranslations(false);
  }, [
    activeQuestion.id,
    activeQuestion.type,
    activeQuestion.text,
    locale,
    dispatch,
    sortedChoices,
    isMountedRef,
    org?.id,
  ]);

  useEffect(() => {
    if (translatedLocaleRef.current === locale && questionIdRef.current === activeQuestion.id) {
      return;
    }

    fetchLocale();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeQuestion.id, locale]);

  return {
    isLoadingTranslations,
    questionTranslation,
    choicesTranslations,
    setLocale,
  };
};

export default useQuizTranslation;
