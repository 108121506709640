import { all, put, select, takeLatest } from 'redux-saga/effects';

import {
  answerQuizQuestion,
  setQuizProcessingFalse,
  answerQuizQuestionSuccess,
  fetchQuiz,
  fetchQuizError,
  fetchQuizSuccess,
  selectQuiz,
  setQuizSettings,
  updateQuizBiteShareStatus,
} from './quiz.slice';

import history from '../../navigation/history';
import {
  getBiteShareUser,
  getBiteShareSections,
  getQuiz,
  getSettings,
  answerQuestion,
  getSettingsById,
} from '../api/calls/quiz.calls';
import { getById as getBiteShareById, uploadImage } from '../api/calls/bite.calls';
import { getOrgSettings, setBranding } from '../organization/organization.slice';
import { dataURItoBlob } from '../../utils/utils';
import handleUnaothrizedContentResponse from '../../utils/handleUnaothrizedContentResponse';
import { getIntroMedia } from '../../utils/bite';
import { applyLocaleIfNeeded, setPreferredLanguage } from '../../locale/i18n';
import { setShowSomethingWentWrong } from '../appActivity/appActivity.slice';
import { log, logError } from '../tracking/tracking.slice';
import { cloneDeep } from 'lodash';
import { setIsNothingToDisplayModalShown } from '../auth/auth.actions';
import { EShowSomethingWentWrongError } from '../appActivity/appActivity.types';
import { setGeofenceContentInfo } from '../geofence/geofence.slice';
import { EContentType } from '../geofence/geofence.types';
import { applyOnboardingToken } from '../onboarding/onboarding.saga';
import { getOrganizationFeatureFlags } from '../profile/profile.actions';
import * as calls from '../api/calls/BiteShareUser.calls';
import { updateBiteShareUserProgressSuccess } from '../biteShareUser/biteShareUser.actions';

function* fetchQuizRequest(action) {
  const { quizId, processId } = action.payload;
  yield put(
    log({
      event: 'quiz.saga fetchQuizRequest: start',
      processId,
      data: { quizId },
    }),
  );

  try {
    yield applyOnboardingToken();
    const { data: quiz } = yield getQuiz(quizId, { 'x-geofence': true });
    yield put(
      log({
        event: 'quiz.saga fetchQuizRequest: getQuiz result',
        processId,
        data: { quiz },
      }),
    );

    yield put(getOrganizationFeatureFlags(quiz.organization));

    yield setQuizResultSaga({ quizId, quiz, processId });
  } catch (error) {
    yield put(
      logError({
        event: 'quiz.saga fetchQuizRequest: error',
        processId,
        data: {
          error,
          errorResponse: cloneDeep(error?.response),
        },
      }),
    );

    if (error?.response?.data?.detail?.orgid) {
      yield setPreferredLanguage({ orgId: error.response.data.detail.orgid });
    }

    // On getting 428 status code, we should set geofence component active and set geofence content info
    if (error?.response?.status === 428) {
      yield put(setGeofenceContentInfo({ type: EContentType.quiz, id: quizId, processId }));
      history.replace('/playlists');
      return;
    }

    yield handleUnaothrizedContentResponse({ error, processId });
    yield put(fetchQuizError());
  }
}

export function* setQuizResultSaga({ quizId, quiz, processId }: { quizId: number; quiz: any; processId: string }) {
  let introSection = null;

  if (quiz.bite_shares.length === 0) {
    yield put(setIsNothingToDisplayModalShown(true));
    history.push('/');
    return;
  }

  if (quiz.bite_shares[0].playlist_section === 'intro') {
    introSection = quiz.bite_shares[0];
  }

  const questionsBiteShares = quiz.bite_shares.filter((bite) => bite.playlist_section === 'body');
  yield put(getOrgSettings(quiz.organization));

  yield put(
    log({
      event: 'quiz.saga setQuizResultSaga: getOrgSettings done',
      processId,
    }),
  );

  const getSettingsPromise = getSettings(quizId);
  const getIntroBiteSharePromise = introSection
    ? getBiteShareById(introSection.id, {
        extend: ['enhancements', 'subtitles', 'sections'],
      })
    : Promise.resolve();
  const getBiteShareUsersPromises = questionsBiteShares.map((qbs) => getBiteShareUser(qbs.id));
  const getBiteSharesForQuestionsSectionsPromises = questionsBiteShares.map((qbs) => getBiteShareSections(qbs.id));
  const [settingsResponse, introBiteShareResponse, ...biteShareUsersAndBiteSharesForQuestionsSectionsResponse] =
    yield Promise.all([
      getSettingsPromise,
      getIntroBiteSharePromise,
      ...getBiteShareUsersPromises,
      ...getBiteSharesForQuestionsSectionsPromises,
    ]);
  const introBiteShare = introBiteShareResponse?.data;
  const biteShareUsers = biteShareUsersAndBiteSharesForQuestionsSectionsResponse.slice(0, questionsBiteShares.length);
  const biteSharesForQuestionsSectionsResponse = biteShareUsersAndBiteSharesForQuestionsSectionsResponse.slice(
    questionsBiteShares.length,
  );
  const questions = biteSharesForQuestionsSectionsResponse
    .map((item) => item.data.find((section) => section.type === 'question'))
    .map((x, index) => ({
      id: x.id,
      media: x.media,
      cover_url: questionsBiteShares[index].cover_url,
      ...x.questions[0],
      choices: x.questions[0].choices || [],
      bite_share_user_id: biteShareUsers[index].id,
      userChoicesIds: biteShareUsers[index].choices.map(({ choice }) => choice?.id),
      isAnswered: !!biteShareUsers[index].choices[0],
      openEndedText: biteShareUsers[index].choices[0]?.text,
      answerMedia: biteShareUsers[index].choices[0]?.media,
      bite_progress: biteShareUsers[index].bite_progress,
    }));

  const introMedia = introSection && getIntroMedia(introBiteShare);
  const formattedQuiz = {
    id: quizId,
    settings: settingsResponse.data[0],
    intro: introSection
      ? {
          subject: introSection.subject,
          text: introSection.description,
          cover_url: introSection.cover_url,
          media_url: introMedia?.media_url,
          image_url: introMedia?.image_url,
          media_type: introMedia?.file_type,
          width: introMedia?.width,
          height: introMedia?.height,
        }
      : {},
    questions,
    subject: quiz.subject,
    sideEffects: quiz.side_effects,
    organization: quiz.organization,
    sharingMode: quiz.sharing_mode,
    biteShares: quiz.bite_shares,
    linked_cover_url: quiz.linked_cover_url,
    creator: quiz.creator,
  };

  yield put(
    log({
      event: 'quiz.saga setQuizResultSaga: loading data promise all result',
      processId,
      data: {
        formattedQuiz,
      },
    }),
  );

  const { data: quizSettings } = yield getSettingsById(quizId);

  yield put(
    log({
      event: 'quiz.saga setQuizResultSaga: getSettingsById result',
      processId,
      data: {
        quizSettings,
      },
    }),
  );

  if (quizSettings?.length) {
    yield put(setQuizSettings(quizSettings[0]));
  }

  yield put(fetchQuizSuccess(formattedQuiz));
  yield put(setBranding(quiz.branding));
  yield setPreferredLanguage({ orgId: quiz.organization });
  yield applyLocaleIfNeeded(quiz.branding.locale);
}

function* answerQuizQuestionRequest(action) {
  const { question: questionId, choices_ids: userChoicesIds } = action.payload;
  const { questions } = yield select(selectQuiz);
  const question = questions.find((q) => q.id === questionId);

  const isPreview = new URLSearchParams(window.location.search).has('isPreview');
  if (isPreview && question.isAnswered) {
    yield put(
      answerQuizQuestionSuccess({
        questionId,
        userChoicesIds,
        choices: question.choices,
      }),
    );
    yield redirectAfterAnsweringWithTimeout({ question, questionId, questions });
    return;
  }

  let mediaId = '';
  let { image } = action.payload;

  try {
    if (image) {
      if (typeof image === 'string') {
        image = dataURItoBlob(image);
      }
      const { data } = yield uploadImage(image);
      mediaId = data.id;
    }
    const { data } = yield answerQuestion({ ...action.payload, media: mediaId });
    yield put(answerQuizQuestionSuccess({ ...data, userChoicesIds, questionId }));
    yield redirectAfterAnsweringWithTimeout({ question, questionId, questions });
  } catch (error) {
    yield put(
      logError({
        event: 'answerQuizQuestionRequest: error',
        data: {
          error,
          errorResponse: cloneDeep(error?.response),
        },
      }),
    );
    yield put(setShowSomethingWentWrong({ type: EShowSomethingWentWrongError.DEFAULT }));
    yield put(setQuizProcessingFalse());
  }
}

function* updateQuizBiteShareStatusSaga(action) {
  const { bite_progress, activeQuestion } = action.payload;

  try {
    if (activeQuestion.bite_share_user_id && activeQuestion.bite_progress !== bite_progress) {
      const { data } = yield calls.updateBiteShareUserProgress(activeQuestion.bite_share_user_id, { bite_progress });
      yield put(updateBiteShareUserProgressSuccess(data));
    }
  } catch (error) {
    yield put(logError({ event: 'updateBiteShareUserProgress: error', data: { error } }));
  }
}

export default function* quizSaga() {
  yield all([
    takeLatest(answerQuizQuestion, answerQuizQuestionRequest),
    takeLatest(fetchQuiz, fetchQuizRequest),
    takeLatest(updateQuizBiteShareStatus, updateQuizBiteShareStatusSaga),
  ]);
}

function redirectAfterAnsweringWithTimeout({ question, questionId, questions }) {
  if (question.type === 'open ended') {
    redirectAfterAnswering({ questionId, questions });
  } else {
    // give the user time to see the results
    setTimeout(function () {
      redirectAfterAnswering({ questionId, questions });
    }, 3000);
  }
}
export function redirectAfterAnswering({ questionId, questions }) {
  if (isLastQuestion({ questionId, questions })) {
    redirectToEnd();
  } else {
    redirectToNextQuestion({ questionId, questions });
  }
}

function redirectToEnd() {
  const quizId = history.location.pathname.split('/')[2];
  history.push(`/quiz/${quizId}/end`);
}

function redirectToNextQuestion({ questionId, questions }) {
  const quizId = history.location.pathname.split('/')[2];
  const currentQuestionIndex = questions.findIndex((q) => q.id === questionId);
  const nextQuestionId = questions[currentQuestionIndex + 1].id;
  history.push(`/quiz/${quizId}/question/${nextQuestionId}${window.location.search}`);
}

function isLastQuestion({ questionId, questions }) {
  return questions.findIndex((q) => q.id === questionId) === questions.length - 1;
}
